export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"},{"name":"google-site-verification","content":"EzIZ8SQh2Q_-WWk94r5O-V6yqajnbZ7_dPXSAw7lA14"}],"link":[],"style":[],"script":[{"src":"https://use.typekit.net/ftv7fjt.js"},{"hid":"adsbygoogle-script","defer":true,"crossorigin":"anonymous","src":"//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1755473979521561"},{"hid":"adsbygoogle","innerHTML":"if (!window.__abg_called){ (window.adsbygoogle = window.adsbygoogle || []); adsbygoogle.pauseAdRequests=0;\n      adsbygoogle.push({\n        google_ad_client: \"ca-pub-1755473979521561\",\n        overlays: {bottom: false},\n        enable_page_level_ads: true\n      }); window.__abg_called = true;}"}],"noscript":[],"htmlAttrs":{"lang":"ja"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const componentIslands = true

export const devPagesDir = null

export const devRootDir = null