import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const light = {
  colors: {
    primary: "#81D8D0",
    secondary: "#B1E7E2",
    accent: "#2196f3",
    error: "#e91e63",
    warning: "#ff9800",
    info: "#3f51b5",
    success: "#4caf50",
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    theme: {
      themes: {
        light,
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});
