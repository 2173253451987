import * as contentful from "contentful";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  // 本番ではcontentful.default.createClientとなる
  const createClientFunc = contentful.createClient
    ? contentful.createClient
    : contentful.default.createClient;

  const client = createClientFunc(config.public.ctf);

  return {
    provide: {
      contentfulClient: client,
    },
  };
});
