import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import contentful_bOzdIRmqaX from "/vercel/path0/plugins/contentful.js";
import lodash_oeOolPNC0c from "/vercel/path0/plugins/lodash.js";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/plugins/vuetify.ts";
export default [
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  contentful_bOzdIRmqaX,
  lodash_oeOolPNC0c,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]