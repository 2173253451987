<script setup lang="ts">
const route = useRoute();
const title = "DOGFUL(ドッグフル) | 愛犬と一緒にお出かけしよう";
const description =
  `DOGFUL(ドッグフル)| 全国のドッグランやペット同伴可能なレストラン、カフェ、ホテルをご紹介。\
            日本最大級のドッグラン検索サイト。愛犬と過ごせるペット同伴可なカフェやホテルも検索できます。\
            地図情報・営業時間・写真の情報も揃っています。\
            `.replace(/\s+/g, "");

useHead({
  title,
  meta: [
    { name: "description", content: description },
    { name: "thumbnail", content: "https://dog-ful.com/images/logo.png" },
    { name: "og:type", content: "website" },
    { name: "og:url", content: route.fullPath },
    { name: "og:site_name", content: "日本最大級のドッグラン検索サイト" },
    { name: "og:title", content: title },
    { name: "og:description", content: description },
    { name: "og:image", content: "https://dog-ful.com/images/logo.png" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: title },
    { name: "twitter:image", content: "https://dog-ful.com/images/logo.png" },
  ],
});
</script>

<template lang="pug">
div
  NuxtLayout
    NuxtLoadingIndicator
    NuxtPage
</template>
