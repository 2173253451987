export default defineNuxtPlugin(async ({ vueApp }) => {
  const sentry = await import("@sentry/nuxt");
  sentry.init({
    // If set up, you can use your runtime config here
    dsn: useRuntimeConfig().public.sentry.dsn,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    enabled: process.env.NODE_ENV === "production",
  });
});
