import type { RouterConfig } from "@nuxt/schema";
import qs from "qs";

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  // Fix router query parser - https://github.com/vuejs/vue-router/issues/1259#issuecomment-1571553624
  parseQuery: (query) => {
    return qs.parse(query, { arrayFormat: "bracket" });
  },
  stringifyQuery: (params) => {
    if (Object.keys(params).length == 0) {
      return "";
    } else {
      return qs.stringify(params, {
        arrayFormat: "bracket",
      });
    }
  },
};
